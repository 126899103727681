import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import SupportiveCareSubmenu from "../../components/template-partials/supportive-care/supportive-care-submenu"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"


import headingBg from "../../images/4.0-headerbg.jpg"
import headingRound from "../../images/supportive-care-nav-image.jpg"
import painSigns from "../../images/2x4.8.1_pain_signs.png"
import nausea from "../../images/2x4.1.1_nausea.png"
import bloodCount from "../../images/2x4.1.4_bloodcount.png"
import hairLoss from "../../images/2x4.1.7_hairloss.png"
import mouthSores from "../../images/2x4.1.6_mouthsores.png"
import constipation from "../../images/2x4.1.2_constipation.png"
import decreasedAppetite from "../../images/2x4.1.3_decreasedappetite.png"

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const SupportiveCareIndexPage = () => {
  return (
    <Layout>
      <SEO title="Neuroblastoma Supportive Care" description="During the course of your child’s treatment journey, neuroblastoma therapies may cause side effects. Find out what you and your child’s healthcare team can do to help manage common side effects." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Supportive Care Overview">
        <h1 className="section__heading section__heading--green h1">Supportive Care Overview</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Supportive Care`}>
              <SupportiveCareSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <p>Therapies may cause side effects at different points in your child’s journey. The members of your child’s healthcare team are experts in managing them, but you should also be aware of some of the most common side effects and when they might occur during treatment. </p>
                <p>Learn about some of the common side effects of treatment and how the healthcare team may manage each. In this section, you will find information to help you understand and manage the different side effects for your child.</p>
              </div>
            </div>
            <div className="row align-bottom">
              <div className="columns large-4 small-12 text-center lg_mt-2 mt-4">
                <Link to="/supportive-care/nausea-vomiting/">
                  <img src={nausea} alt="Nausea and Vomiting" style={{maxWidth: `110px`}}/>
                  <p>Nausea and Vomiting <FontAwesomeIcon icon={faChevronRight} /></p>
                </Link>
              </div>
              <div className="columns large-4 small-12 text-center lg_mt-2 mt-4">
                <Link to="/supportive-care/constipation-diarrhea/">
                  <img src={constipation} alt="Constipation and Diarrhea" style={{maxWidth: `107px`}}/>
                  <p>Constipation and Diarrhea <FontAwesomeIcon icon={faChevronRight} /></p>
                </Link>
              </div>
              <div className="columns large-4 small-12 text-center lg_mt-2 mt-4">
                <Link to="/supportive-care/diet-nutrition/">
                  <img src={decreasedAppetite} alt="Decreased Appetite and Nutrition" style={{maxWidth: `142px`}}/>
                  <p>Decreased Appetite and Nutrition <FontAwesomeIcon icon={faChevronRight} /></p>
                </Link>
              </div>
            </div>
            <div className="row align-bottom">
              <div className="columns large-4 small-12 text-center lg_mt-2 mt-4">
                <Link to="/supportive-care/blood-counts/">
                  <img src={bloodCount} alt="Blood Counts" style={{maxWidth: `116px`}}/>
                  <p>Blood Counts <FontAwesomeIcon icon={faChevronRight} /></p>
                </Link>
              </div>
              <div className="columns large-4 small-12 text-center lg_mt-2 mt-4">
                <Link to="/supportive-care/hair-loss/">
                  <img src={hairLoss} alt="Hair Loss" style={{maxWidth: `112px`}}/>
                  <p>Hair Loss <FontAwesomeIcon icon={faChevronRight} /></p>
                </Link>
              </div>
              <div className="columns large-4 small-12 text-center lg_mt-2 mt-4">
                <Link to="/supportive-care/mouth-sores/">
                  <img src={mouthSores} alt="Mouth Sores" style={{maxWidth: `141px`}}/>
                  <p>Mouth Sores <FontAwesomeIcon icon={faChevronRight} /></p>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="columns large-4 small-12 text-center lg_mt-2 mt-4 mb-4">
                <Link to="/supportive-care/pain/">
                  <img src={painSigns} alt="Pain" style={{maxWidth: `111px`}}/>
                  <p>Pain <FontAwesomeIcon icon={faChevronRight} /></p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Section>




      <InteriorPageFooter>

      <UpNextCard topic="Nausea and Vomiting" title="Nausea and vomiting" lead="Learn about the potential for nausea and vomiting, and how they are managed." url="/supportive-care/nausea-vomiting/" />

      </InteriorPageFooter>

    </Layout>
  )
}

export default SupportiveCareIndexPage
